import { createApp } from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { useCookies } from "vue3-cookies"
import store from '@/store/index'
import { LANGUAGE } from '@/const/language'
import { USER, TOKEN, SETTING_NAVBAR_MODE, SETTING_SIDEBAR_MODE, SETTING_BREAKCRUMB } from '@/const/cookie'
import { CHANGE_COUNTRY } from '@/const/store'
import { v4 as uuidv4 } from 'uuid'
import { useRouter } from 'vue-router'
import i18n from '../../createI18n'
const router = useRouter()
const app = createApp({})
app.use(VueAxios, axios)
app.use(i18n)
const service = {
    init() {
        app.axios.defaults.baseURL = process.env.VUE_APP_BASE_API
        app.axios.defaults.headers.common['Content-Type'] = 'application/json'
        app.axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
        app.axios.defaults.headers.common['Access-Control-Allow-Methods'] = 'GET, POST, OPTIONS, PUT, DELETE'
        app.axios.defaults.headers.common['Access-Control-Max-Age'] = '3600'
        app.axios.defaults.headers.common['Access-Control-Allow-Credentials'] = 'true'
        app.axios.defaults.headers.common['Access-Control-Allow-Headers'] = 'access-control-allow-headers,access-control-allow-methods,access-control-allow-origin'
        let clientMessage = uuidv4()
        app.axios.defaults.headers.common['clientMessageId'] = clientMessage

        const { cookies } = useCookies();
        let lang_cookie = cookies.get(LANGUAGE);
        let user = cookies.get(USER);
        let tokenCookies = cookies.get(TOKEN);
        let breakcrumb = cookies.get(SETTING_BREAKCRUMB)
        if (breakcrumb != null && breakcrumb != 'undefined') {
            store.dispatch('setBreadcrumb', JSON.parse(breakcrumb))
        }
        if (lang_cookie != null) {
            store.commit(CHANGE_COUNTRY, lang_cookie)
        }
        if (user !== null) {
            store.dispatch('setLoggedIn', true)
           
            let token = JSON.parse(JSON.stringify(user));
            app.axios.defaults.headers.common['access_token'] = token.tokenType + ' '+  token.accessToken;
            app.axios.defaults.headers.common['refresh_token'] = token.refreshToken;
            app.axios.defaults.headers.common['session'] = token.sessionId;
        }

        let settingNavbarMode = cookies.get(SETTING_NAVBAR_MODE);
        if (settingNavbarMode !== null) {
            store.dispatch('setNavbarVariant', settingNavbarMode)
        }
        let settingSidebarMode = cookies.get(SETTING_SIDEBAR_MODE);
        if (settingSidebarMode !== null) {
            store.dispatch('setSidebarSkin', settingSidebarMode)
        }

        app.axios.interceptors.response.use((response) => {
            return response
        }, async (error) => {
            store.dispatch('setLoggedIn', false)
            cookies.remove('user')
            localStorage.removeItem('user')
            router.push({ path: '/login' })
        })
    },
    get(resource, params) {
        return app.axios.get(resource, params).catch(error => {
            const { cookies } = useCookies();
            store.dispatch('setLoggedIn', false)
            cookies.remove('user')
            localStorage.removeItem('user')
            throw error
        })
    },
    post(resource, params) {
        return app.axios.post(`${resource}`, params).catch(error => {
            const { cookies } = useCookies();
            store.dispatch('setLoggedIn', false)
            cookies.remove('user')
            localStorage.removeItem('user')
            throw error
        })
    },
    patch(resource, params) {
        return app.axios.patch(`${resource}`, params).catch(error => {
            const { cookies } = useCookies();
            store.dispatch('setLoggedIn', false)
            cookies.remove('user')
            localStorage.removeItem('user')
            throw error
        })
    },
    getNoParam(resource) {
        return app.axios.get(resource).catch(error => {
            throw error
        })
    },
    getFileNoParam(resource) {
        app.axios.defaults.responseType = 'blob';
        return app.axios.get(resource).catch(error => {
            const { cookies } = useCookies();
            store.dispatch('setLoggedIn', false)
            cookies.remove('user')
            localStorage.removeItem('user')
            throw error
        })
    },
    postNoParam(resource) {
        return app.axios.post(`${resource}`).catch(error => {
            const { cookies } = useCookies();
            store.dispatch('setLoggedIn', false)
            cookies.remove('user')
            localStorage.removeItem('user')
            throw error
        })
    },
    uploadFile(resource, params) {
        app.axios.defaults.headers.common['Content-Type'] = 'multipart/form-data'
        return app.axios.post(`${resource}`, params).catch(error => {
            const { cookies } = useCookies();
            store.dispatch('setLoggedIn', false)
            cookies.remove('user')
            localStorage.removeItem('user')
            throw error
        })
    },
    deleteNoParam(resource) {
        return app.axios.delete(`${resource}`).catch(error => {
            const { cookies } = useCookies();
            store.dispatch('setLoggedIn', false)
            cookies.remove('user')
            localStorage.removeItem('user')
            throw error
        })
    }
}
export default service
