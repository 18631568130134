import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
import { useCookies } from "vue3-cookies"
import { ElNotification } from 'element-plus'
import { BUILDING_ADDRESS, BUILDING_ID, BUILDING_NAME, ROOM_ID, ROOM_NAME, CONTRACT_ID, CONTRACT_NAME, RECEIPT_ID } from '@/const/cookie'
import store from '@/store/index'
import Utils from './Utils'

const commons = {
    setStore(key, value) {
        store.dispatch(key, value)
    },
    setCokies(key, value) {
        const { cookies } = useCookies();
        cookies.set(key, value)
    },
    setRoomId(value) {
        const { cookies } = useCookies();
        cookies.set(ROOM_ID, value)
    },
    setBuildId( value) {
        const { cookies } = useCookies();
        cookies.set(BUILDING_ID, value)
    },
    setContractId( value) {
        const { cookies } = useCookies();
        cookies.set(CONTRACT_ID, value)
    },
    setContractName( value) {
        const { cookies } = useCookies();
        cookies.set(CONTRACT_NAME, value)
    },
    setStoreChange() {
        store.dispatch('setBuildingChange', Utils.randomText(5))
    },
    getReceiptId() {
        const { cookies } = useCookies();
        return cookies.get(RECEIPT_ID);
    },
    getBuildId() {
        const { cookies } = useCookies();
        return cookies.get(BUILDING_ID);
    },
    removeBuildId() {
        const { cookies } = useCookies();
        return cookies.remove(BUILDING_ID);
    },
    getBuildName() {
        const { cookies } = useCookies();
        return cookies.get(BUILDING_NAME);
    },
    removeBuildName() {
        const { cookies } = useCookies();
        return cookies.remove(BUILDING_NAME);
    },
    getContractId() {
        const { cookies } = useCookies();
        return cookies.get(CONTRACT_ID);
    },
    getContractName() {
        const { cookies } = useCookies();
        return cookies.get(CONTRACT_NAME);
    },
    getBuildAddtress() {
        const { cookies } = useCookies();
        return cookies.get(BUILDING_ADDRESS);
    },
    getRoomId() {
        const { cookies } = useCookies();
        return cookies.get(ROOM_ID);
    },
    getRoomName() {
        const { cookies } = useCookies();
        return cookies.get(ROOM_NAME);
    },
    routerGo(val: number) {
        const router = useRouter()
        router.go(val)
        return false
    },
    downloadFile(url) {
        let element = document.createElement('a');
        element.href = url;
        element.target = '_blank';
        element.setAttribute('download', 'download');
        element.click();

    },
    downloadFileStatic(url) {
        let element = document.createElement('a');
        element.href = process.env.VUE_APP_DOMAIN_ADMIN + url;
        element.target = '_blank';
        element.setAttribute('download', 'download');
        element.click();

    },
    getSubString(msgCode, start, end) {
        if (msgCode == '') return msgCode
        if (msgCode == undefined) return msgCode
        if (msgCode == null) return msgCode
        return msgCode.substring(start, end)
    },
    showMsgSuccess(msgCode) {
        ElMessage({
            message: msgCode,
            type: 'success',
        })
    },
    showMsgWarning(msgCode) {
        ElMessage({
            message: msgCode,
            type: 'warning',
        })
    },
    showMsgError(msgCode) {
        ElMessage({
            message: msgCode,
            type: 'error',
        })
    },
    showMsg(msgCode) {
        ElMessage(msgCode)
    },
    redirectPage(url: any, param: any) {
        const router = useRouter()
        router.push({ name: url, params: param });
    },
    getBuildingId() {
        return localStorage.getItem('buildingId') || 0
    },
    showNotificationSuccess(msgCode) {
        ElNotification({
            title: 'Success',
            message: msgCode,
            type: 'success',
          })
    },
    showNotificationWarning(msgCode) {
        ElNotification({
            title: 'Warning',
            message: msgCode,
            type: 'warning',
          })
    },
    showNotificationInfo(msgCode) {
        ElNotification({
            title: 'Info',
            message: msgCode,
            type: 'info',
          })
    },
    showNotificationError(msgCode) {
        ElNotification({
            title: 'Error',
            message: msgCode,
            type: 'error',
          })
    },
}
export default commons