import { RouteRecordRaw, Router } from "vue-router"
import Index from '../views/dg6789admin6868/Index.vue'
import routesClient from './client'
const routesAdmin: Array<RouteRecordRaw> = [
  {
    path: '/home',
    name: 'home',
    component: Index,
    meta: {
      requiresAuth: true,
      breadcrumb: [{ '/home': 'home' }]
    }
    ,
    children: [
      {
        path: '/admin',
        name: 'index',
        component: () =>
          import('../views/dg6789admin6868/user/UserList.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '/user', name: 'Người sử dụng', status: false }]
        },
      }, {
        path: '/userlist',
        name: 'userlist',
        component: () =>
          import('../views/dg6789admin6868/user/UserList.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '/user', name: 'Người sử dụng', status: false }]
        },
      }, {
        path: '/inform',
        name: 'Inform',
        component: () =>
          import('../views/dg6789admin6868/user/UserInform.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ '/home': 'Home', '/user': 'Người sử dung', '': 'Danh sách client' }]
        }
      }, {
        path: '/user-detail',
        name: 'user-detail',
        component: () =>
          import('../views/dg6789admin6868/user/UserDetail.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ '/home': 'Home', '/user': 'Người sử dung', '': 'Danh sách client' }]
        }
      }, {
        path: '/user-create',
        name: 'user-create',
        component: () =>
          import('../views/dg6789admin6868/user/UserCreate.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ '/home': 'Home', '/user': 'Người sử dung', '': 'Danh sách client' }]
        }
      }, {
        path: '/manage-upload-file',
        name: 'manage-upload-file',
        component: () =>
          import('../views/dg6789admin6868/file/ManagementFile.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ '/home': 'Home', '/file': 'File', '': 'Quản lý file' }]
        }
      }, {
        path: '/manage-search-image',
        name: 'manage-search-image',
        component: () =>
          import('../views/dg6789admin6868/images/ManagementImages.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ '/home': 'Home', '/user': 'ảnh', '': 'Tìm kiếm ảnh' }]
        }
      }, {
        path: '/setting',
        name: 'setting',
        component: () =>
          import('../views/dg6789admin6868/setting/Setting.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ '/home': 'Home', '/setting': 'Cấu hình', '': 'Cài đặt chi tiết' }]
        }
      }

    ]

  },
  {
    path: '/login',
    name: 'Login',
    component: () =>
      import('../views/dg6789admin6868/login/Login.vue'),
    meta: {
      requiresUnauth: true
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: '*',
    component: () =>
      import('../views/404.vue'),
    meta: {
      requiresUnauth: true
    }
  },
  routesClient,
  // {
  //   path: '/indexHome',
  //   name: 'indexHome',
  //   component: () =>
  //     import('../views/client/Index.vue'),
  //   meta: {
  //     requiresUnauth: true
  //   },
  //   children: [
  //     {
  //       path: '/',
  //       name: 'home',
  //       component: () =>
  //         import('../views/client/home/Home.vue'),
  //       meta: {
  //         requiresAuth: false,
  //         breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }]
  //       },
  //     }
  //   ]
  // },
  // {
  //   path: '/',
  //   name: 'home54235234534',
  //   component: () => import('../views/client/Index.vue'),
  //   meta: {
  //     requiresAuth: true,
  //     breadcrumb: [{ '/home54235234534': 'home54235234534' }]
  //   }
  //   ,
  //   children: [
  //     {
  //       path: '/home',
  //       name: 'home',
  //       component: () =>
  //         import('../views/client/home/Home.vue'),
  //       meta: {
  //         requiresAuth: true,
  //         breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '/user', name: 'Người sử dụng', status: false }]
  //       },
  //     }

  //   ]

  // }
]
export default routesAdmin
