export const USER = 'user'
export const USER_LOGINED = 'isUser'
export const TOKEN = 'token'
export const TOKEN_GOOGLE = 'token_google'
export const SETTING_NAVBAR_MODE = 'settingNavbarMode'
export const SETTING_SIDEBAR_MODE = 'settingSidebarMode'
export const SETTING_BREAKCRUMB = 'setBreadcrumb'
export const BUILDING_ID = 'buildingId'
export const BUILDING_NAME = 'buildingname'
export const ROOM_ID = 'roomId'
export const ROOM_NAME = 'roomName'
export const BUILDING_ADDRESS = 'buildAddress'
export const CONTRACT_ID = 'contractId'
export const CONTRACT_NAME = 'contractName'
export const RECEIPT_ID = 'receiptId'

