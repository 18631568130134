const routesClient =
{
    path: '/indexHome',
    name: 'indexHome',
    component: () => import('../views/client/Index.vue'),
    meta: {
        requiresUnauth: true
    },
    children: [
        {
            path: '/',
            name: 'home',
            component: () =>
                import('../views/client/home/Home.vue'),
            meta: {
                requiresAuth: false,
                breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }]
            },
        }
    ]
}
export default routesClient
