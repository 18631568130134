<template>
    <!-- Page Wrapper -->
    <div class="wrapper">
        <!-- Preloader -->
        <!-- <div class="preloader flex-column justify-content-center align-items-center">
            <img class="animation__wobble" src="dist/img/AdminLTELogo.png" alt="AdminLTELogo" height="60" width="60">
        </div> -->
        <slide></slide>
        <nav-ad></nav-ad>
        <!-- Content Wrapper. Contains page content -->
        <div class="content-wrapper">
            <!-- Content Header (Page header) -->
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <!-- /.col -->
                        <div class="col-sm-10">
                            <!-- <ol class="breadcrumb float-sm-left">
                                <li class="breadcrumb-item"><a href="#">Home</a></li>
                                <li class="breadcrumb-item active">Dashboard <el-icon><ArrowDownBold /></el-icon></li>
                            </ol> -->
                            <bread-crumb></bread-crumb>
                        </div>
                        <!-- /.col -->
                    </div>
                    <!-- /.row -->
                </div>

                <!-- <nav-tab></nav-tab> -->
                <!-- /.container-fluid -->
            </div>
            <!-- /.content-header -->

            <!-- Main content -->
            <section class="content">
                <router-view></router-view>
            </section>
            <!-- /.content -->
        </div>
        <aside class="control-sidebar control-sidebar-dark">
        </aside>
        <footer-ad></footer-ad>
    </div>
</template>
<style>
@import "../../assets/css/sb-admin.css";
@import "../../assets/css/admin.css";
@import "../../assets/css/admin.min.css";
</style>
<script>
import slideBar from '@/components/common/Sidebar'
import navAdmin from '@/components/common/Nav'
import footerAdmin from '@/components/common/Footer'
import breadcrumb from '@/components/common/Breadcrumb'
// import navTab from '@/components/common/navTab/NavTab'
export default {
    components: {
        'slide': slideBar,
        'nav-ad': navAdmin,
        'footer-ad': footerAdmin,
        'bread-crumb': breadcrumb,
        // 'nav-tab': navTab
    },
    methods: {
        openHelp() {
            this.$router.push('/huongdan')
        }
    }
    
}
</script>

