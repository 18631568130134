export default {
    isCallApi: state => state.isCallApi,
    isCountryChange: state => state.isCountryChange,
    isHistoryBack: state => state.isHistoryBack,
    isBreadcrumb : state => state.isBreadcrumb ,
    menuSidebarCollapsed: (state: any): boolean => state.menuSidebarCollapsed,
    controlSidebarCollapsed: (state: any): boolean => state.controlSidebarCollapsed,
    actionReseted: (state: any): boolean => state.actionReseted,
    darkModeSelected: (state: any): boolean => state.darkModeSelected,
    navbarFixed: (state: any): boolean => state.navbarFixed,
    noBorderSelected: (state: any): boolean => state.noBorderSelected,
    navbarVariant: (state: any): string => state.navbarVariant,
    sidebarSkin: (state: any): string => state.sidebarSkin,
    activeTab:(state:any):number => state.activeTab,
    notReadMsg:(state:any):number => state.notReadMsg,
    receiptId:(state:any):number => state.receiptId,
};
